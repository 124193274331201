import { session} from "@/utils/storage";
import Vue from "vue";
Vue.directive('has', {
  inserted: function (el, binding) {
    let permissionMap = session.get('permission')
    if (!permissionMap) {
      el.style.display = 'none'
      return false
    }
    function permission(value){
      return permissionMap.includes(value)
    }
    if (!permission(binding.value)) {
      // el.parentNode.removeChild(el);
      el.style.display = 'none'
    } else {
      return true
    }
  }
})
