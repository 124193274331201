const session = {
    // 读取sessionStorage
    get: key => {
        try {
            return JSON.parse(window.sessionStorage.getItem(key))
        } catch (err) {
            return window.sessionStorage.getItem(key)
        }
    },
    // 创建sessionStorage
    set: (key,value) => {
        window.sessionStorage.setItem(key,JSON.stringify(value))
    },
    // 移除指定sessionStorage
    remove: key => {
        window.sessionStorage.removeItem(key)
    },
    // 清除sessionStorage
    clear: () => {
        window.sessionStorage.clear()
    }
}

const local = {
    // 读取localStorage
    get: key => {
        try {
            return JSON.parse(window.localStorage.getItem(key))
        } catch (err) {
            return window.localStorage.getItem(key)
        }
    },
    // 创建localStorage
    set: (key,value) => {
        window.localStorage.setItem(key,JSON.stringify(value))
    },
    // 移除指定localStorage
    remove: key => {
        window.localStorage.removeItem(key)
    },
    // 清除localStorage
    clear: () => {
        window.localStorage.clear()
    }
}

export { session, local }



