import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';//引入所以的element组件库
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element_ui.scss'
Vue.use(ElementUI);

import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push//解决路由重新点击报错问题
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

import pagination from "./components/Pagination/index"
Vue.component('pagination',pagination);


// element-ui 全局配置Message距离窗口顶部的偏移量（offset）
import { message } from "./utils/message.js";
Vue.prototype.$message = message;
ElementUI.Dialog.props.closeOnClickModal.default = false//全局设置element-ui Dialog组件的close-on-click-modal属性为false
ElementUI.Table.props.highlightCurrentRow = { type: Boolean, default: true }
import "./styles/date_picker.scss";//DatePicker 日期选择器样式修改
Vue.config.productionTip = false;
import "../src/utils/dialogDrag"

import registerFilter from './utils/filter'
registerFilter(Vue)
import "./permission/index.js"

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
