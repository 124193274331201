<template>
<div class="loading_frame" v-show="show==true">
  <!-- <div class="quan">
      <div class="shui"></div>
    </div> -->
       <img src="../assets/image/jz.gif">
        <p>数据加载中，请稍候…</p>
</div>
</template>
<script>
export default {
    data(){
        return{
            show:false,
        }
    },
	computed: {
	},
    created(){
        setTimeout(()=>{
            this.show=true
        },1000)
    },
    mounted(){
    }

};
</script>
<style lang="scss" scoped>
.loading_frame {
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
    background: rgba(0,0,0,0.8);
    img{
        width: 100px;
        height: 100px;
    }
    p{
        color:#409EFF;
    }
    }
</style>
