/**防止重复点击重复弹出message弹框 */
import { Message } from "element-ui";
//定义一个新的Message方法，多传入一个offset参数
const resetMessage = (options) => {
    return Message({
        ...options,
        offset: 60,
        // zIndex: 300000
    });
};

//重写一遍success的方法,将offset写入options
["success", "warning", "info", "error"].forEach((type) => {
    resetMessage[type] = (options) => {
        if (typeof options === "string") {
            options = {
                message: options,
                offset: 100,
            };
        }
        options.type = type;
        return Message(options);
    };
});
export const message = resetMessage;
