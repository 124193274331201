
function appendZero(obj) { // 补全0
    if (obj < 10) {
        return "0" + obj;
    } else {
        return obj;
    }
}

function formatZero(num, len) { // 统计上面将数字补全0
    if(String(num).length > len) return num;
    return (Array(len).join(0) + num).slice(-len);
}

export const ArrayZero =(num,len) =>{ // 将数字变为数组
    var number= formatZero(num, len),
        output= [],
        sNumber=number.toString();
    for (var i= 0,len=sNumber.length;i<len; i+= 1) {
        output.push(+sNumber.charAt(i));
    }
    return output
}

// 手机号号隐藏
export const formPhone = (value)=>{
    if (!value) return "";
    let str = value;
    str = str.toString().replace(/^(\d{3})(\d{4})(\d{4})/g, '$1****$3')
    return str
}

// 身份证号隐藏
export const formIdcard = (value)=>{
    if (!value) return "";
    let str = value;
    str = str.toString().replace(/^(\w{10})\w*(\w{2})/, '$1****$6')
    return str
}

// 名字隐藏
export const formName = (value)=>{
    let str = value.toString();
    if (str.length == 2) {
        str = str.substr(0,1) + '*'
    } else if(str.length > 2) {
        var char = "";
        for (let i = 0,len = str.length-2 ; i<len; i++) {
            char += '*'
        }
        str = str.substr(0,1) + char + str.substr(-1,1)
    } else {
        str = value
    }
    return str
}

// 时间格式化
export const formTime = (str)=>{
    let time = new Date(str)
    let year =  time.getFullYear();
    let month = appendZero(time.getMonth() + 1);
    let day = appendZero(time.getDate());
    let hour = appendZero(time.getHours());
    let min = appendZero(time.getMinutes());
    return year+ '-' + month + '-' +day + ' ' + hour + ':'+ min
}

export default (Vue) => {
    Vue.filter('formPhone',formPhone)
    Vue.filter('formIdcard',formIdcard)
    Vue.filter('formName',formName)
    Vue.filter('formTime',formTime)
}
