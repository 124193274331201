<!--封装组件  vue页面-->
<!-- 
1. background	是否为分页按钮添加背景色 接受boolean类型
2. current-page	 当前页数，支持 .sync 修饰符  接受类型 number
3. page-size	每页显示条目个数，支持 .sync 修饰符    number
4. page-sizes	每页显示个数选择器的选项设置	number[]	—	[10, 20, 30, 40, 50, 100]
5. total	总条目数	number
6. size-change	pageSize 改变时会触发
7. current-change	currentPage 改变时会触发
-->
<template>
  <div>
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 5
    },
    pageSizes: {
      type:Array,
      default() {
        return [10,20,30,40]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
    //   this.$emit('pagination', { page: this.currentPage, limit: val })
      this.$emit('pagination',val)
    },
    handleCurrentChange(val) {
        console.log(val);
      //this.$emit('pagination', { page: val, limit: this.pageSize })
      this.$emit('pagination')
    }
  }
}
</script>
