<template>
  <div id="app">
    <Loading v-if="LOADING"></Loading>
    <router-view></router-view>
    <div>
    </div>
  </div>
</template>
<script>
import Loading from '@/components/loading.vue';
import { mapState } from 'vuex';
export default {
  data(){
    return{
      SpringFrame:false,
      list:[],
    }
  },
  components: {
		Loading
	},
	computed: {
		...mapState(['LOADING','menuindex']),
	},
  created(){
    window.addEventListener('popstate', this.popstate, false);
  },
  mounted(){
  },
  methods:{
    popstate(e){
      setTimeout(()=>{
        const BackMetaIndex = this.$route.meta.menuindex;
        this.$store.commit('menustate',BackMetaIndex)
      },10)
    },
  }
}
</script>
<style lang="scss">
.el-message {
  z-index: 999999!important;
}
*{
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  min-width: 500px;
  height: 100%;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
</style>
